@use '@/styles/utils/mixins.scss' as *;

.customCheckbox {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  .customCheckboxLabelBox {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .customCheckboxLabelContainer {
    display: flex;
    gap: 0.25rem;
    align-items: first baseline;
  }
  .customCheckboxLabel {
    font-size: 1rem;
    font-weight: bold;
    color: $dark;
    margin-bottom: 0;
    line-height: 1;
    a {
      color: $primary;
      text-decoration: inherit;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
  .customCheckboxDescription {
    font-size: 0.875rem;
    font-weight: 400;
    color: $neutral07;
    a {
      color: $primary;
      text-decoration: inherit;
      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;
      }
    }
  }
}
