@use '@/styles/utils/mixins.scss' as *;

.float-label {
  position: relative;
  font-family: 'Fakt Pro', sans-serif;

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0.75rem;
    top: 1rem;
    z-index: 99;
    font-size: 1rem;
    background: $transparent;
    padding: 0;
    color: $neutral07;
    transition: all 0.2s ease-in-out;

    &.has-value {
      color: $neutral07;
      font-size: 0.75rem;
    }
  }

  .float-input {
    padding: 1rem 0.75rem;
    font-size: 1rem !important;
    line-height: 1.2;
    font-family: 'Fakt Pro', sans-serif;
    transition: all 0.2s ease-in-out;
    &:focus {
      border-color: $primary;
      outline: none;
    }
  }
  input.ant-input {
    font-family: 'Fakt Pro', sans-serif;
    font-size: 1rem !important;
    color: $dark !important;
  }
  &.has-value {
    .float-input {
      padding-top: 2rem;
    }
  }
  .ant-form-item-feedback-icon {
    display: none;
  }
}
